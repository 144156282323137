export default {
	name: "shippingInfo",
	components: {

	},
	data: () => {
		return {
            country: "United States",
            logisticsList:[
                {
                    country: "United States",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "15-20 Days",
                            costs: "US$3.9"
                        },{
                            title: "Express Shipping",
                            time: "7-11 Days",
                            costs: "US$12.9"
                        }
                    ]
                },{
                    country: "Mexico",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "16-20 Days",
                            costs: "US$11.9"
                        }
                    ]
                },{
                    country: "Puerto Rico",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "Around 27 Days",
                            costs: "US$12.9"
                        }
                    ]
                },{
                    country: "Canada",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "7-11 Days",
                            costs: "US$9.5"
                        }
                    ]
                },{
                    country: "United Kingdom",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "6-10 Days",
                            costs: "US$8.9"
                        }
                    ]
                },{
                    country: "France",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "10-15 Days",
                            costs: "US$4.9"
                        }
                    ]
                },{
                    country: "Germany",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "10-15 Days",
                            costs: "US$4.9"
                        }
                    ]
                },{
                    country: "Italy",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "5-8 Days",
                            costs: "US$5.9"
                        }
                    ]
                },{
                    country: "Bulgaria",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "20 Days",
                            costs: "US$4.9"
                        }
                    ]
                },{
                    country: "Spain",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "10-18 Days",
                            costs: "US$4.9"
                        }
                    ]
                },{
                    country: "Denmark",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "10-17 Days",
                            costs: "US$4.9"
                        }
                    ]
                },{
                    country: "Malta",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "25 Days",
                            costs: "US$7.9"
                        }
                    ]
                },{
                    country: "Romania",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "18 Days",
                            costs: "US$5.9"
                        }
                    ]
                },{
                    country: "Slovenia",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "25 Days",
                            costs: "US$5.9"
                        }
                    ]
                },{
                    country: "Slovakia",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "21 Days",
                            costs: "US$5.9"
                        }
                    ]
                },{
                    country: "Ukraine",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "15-20 Days",
                            costs: "US$4.9"
                        }
                    ]
                },{
                    country: "Estonia",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "18 Days",
                            costs: "US$5.9"
                        }
                    ]
                },{
                    country: "Greece",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "15-20 Days",
                            costs: "US$4.9"
                        }
                    ]
                },{
                    country: "Croatia",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "17-22 Days",
                            costs: "US$8.9"
                        }
                    ]
                },{
                    country: "Netherlands",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "10-18 Days",
                            costs: "US$5.9"
                        }
                    ]
                },{
                    country: "Austria",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "8-15 Days",
                            costs: "US$5.9"
                        }
                    ]
                },{
                    country: "Belgium",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "10-18 Days",
                            costs: "US$4.9"
                        }
                    ]
                },{
                    country: "Switzerland",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "7-15 Days",
                            costs: "US$7.9"
                        }
                    ]
                },{
                    country: "Czech",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "10-18 Days",
                            costs: "US$5.9"
                        }
                    ]
                },{
                    country: "Finland",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "10-15 Days",
                            costs: "US$7.9"
                        }
                    ]
                },{
                    country: "Ireland",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "15 Days",
                            costs: "US$6.9"
                        }
                    ]
                },{
                    country: "Lithuania",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "15-20 Days",
                            costs: "US$5.9"
                        }
                    ]
                },{
                    country: "Luxembourg",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "Around 12 Days",
                            costs: "US$5.0"
                        }
                    ]
                },{
                    country: "Poland",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "13-15 Days",
                            costs: "US$5.9"
                        }
                    ]
                },{
                    country: "Portugal",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "Within 15 Days",
                            costs: "US$4.9"
                        }
                    ]
                },{
                    country: "Sweden",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "15-20 Days",
                            costs: "US$6.9"
                        }
                    ]
                },{
                    country: "Australia",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "13-15 Days",
                            costs: "US$5.9"
                        }
                    ]
                },{
                    country: "Taiwan",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "Around 9 Days",
                            costs: "US$4.9"
                        }
                    ]
                },{
                    country: "Thailand",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "9-12 Days",
                            costs: "US$2.9"
                        }
                    ]
                },{
                    country: "Singapore",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "Within 10 Days",
                            costs: "US$2.9"
                        }
                    ]
                },{
                    country: "Hongkong",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "1-3 Days",
                            costs: "US$3.9"
                        }
                    ]
                },{
                    country: "India",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "15-25 Days",
                            costs: "US$6.9"
                        }
                    ]
                },{
                    country: "South Korea",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "7-12 Days",
                            costs: "US$4.9"
                        }
                    ]
                },{
                    country: "Philippine",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "7-15 Days",
                            costs: "US$4.9"
                        }
                    ]
                },{
                    country: "Japan",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "7-15 Days",
                            costs: "US$5.9"
                        }
                    ]
                },{
                    country: "Israel",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "10-15 Days",
                            costs: "US$8.9"
                        }
                    ]
                },{
                    country: "Turkey",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "7-12 Days",
                            costs: "US$3.9"
                        }
                    ]
                },{
                    country: "Saudi Arabia",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "7-15 Days",
                            costs: "US$5.9"
                        }
                    ]
                },{
                    country: "United ArabEmirates",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "8-12 Days",
                            costs: "US$5.9"
                        }
                    ]
                },{
                    country: "Cyprus",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "26 Days",
                            costs: "US$8.9"
                        }
                    ]
                },{
                    country: "South Africa",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "27 Days",
                            costs: "US$8.9"
                        }
                    ]
                },{
                    country: "Brazil",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "15-20 Days",
                            costs: "US$7.9"
                        }
                    ]
                },{
                    country: "Chile",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "12-15 Days",
                            costs: "US$6.9"
                        }
                    ]
                },{
                    country: "Colombia",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "25 Days",
                            costs: "US$8.9"
                        }
                    ]
                },{
                    country: "Peru",
                    timeList: [
                        {
                            title: "Standrad Shipping",
                            time: "20 Days",
                            costs: "US$12.9"
                        }
                    ]
                }
            ],
            shopingList: [
                {
                    title: "Standrad Shipping",
                    time: "15-20 Days",
                    costs: "US$3.9"
                },{
                    title: "Express Shipping",
                    time: "7-11 Days",
                    costs: "US$12.9"
                }
            ]
		}
	},
	watch: {
        '$route': 'fetchData',
        country: {
            deep: true,
            handler: function(val){
                this.country = val;
                this.fetchData();
            },
        },
	},
	created() {
		
	},
	mounted() {
        this.$forceUpdate();
	},
	computed: {

	},
	methods: {
        fetchData() {
            this.$forceUpdate();
        },
        backClick() {
            this.$router.go(-1);
        },
        selectChange() {
            for(let i = 0; i < this.logisticsList.length; i++){
                if(this.country == this.logisticsList[i].country){
                    this.shopingList = this.logisticsList[i].timeList;
                }
            }
            // this.fetchData();
        }
	}
}
