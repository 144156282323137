<template>
    <div class="shippingInfo">
        <div class="shippingInfo-title">
            <div class="back" @click="backClick">
                <img src="@/assets/images/personal/z.png" />
                <span>Back</span>
            </div>
            <h5>Shipping Info</h5>
        </div>
        <div class="shippingInfo-main">
            <div>
                <span>Please find the estimated shipping cost in the table below. Actual shipping cost will be calcualted when you place an order.</span>
            </div>
            <div class="main-title">
                <p>Free Shipping on all orders over <span>US$59.00, GLOBALLY</span></p>
            </div>
            <div class="main-select">
                <span>Shipping to</span>
                <el-select size="mini" v-model="country" placeholder="Select" @change="selectChange">
                    <el-option v-for="(item, index) in logisticsList" :key="index" :label="item.country" :value="item.country">
                    </el-option>
                </el-select>
            </div>
            <div class="main-table">
                <div class="header">
                    <div class="method">
                        <span>Shipping  Method</span>
                    </div>
                    <div class="time">
                        <span>Shipping  Time</span>
                    </div>
                    <div class="costs">
                        <span>Costs</span>
                    </div>
                </div>
                <div class="body">
                    <div class="tr" v-for="(item, index) in shopingList" :key="index">
                        <div class="method">
                            <span>{{item.title}}</span>
                        </div>
                        <div class="time">
                            <span>{{item.time}}</span>
                        </div>
                        <div class="costs">
                            <span>{{item.costs}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-notes">
                <h5>Notes</h5>
                <div class="notes-text">
                    <p>1.<span>For all orders over US$59.00</span>, We provide free shipping to any place we can reach in the world. Please contact us if you don't see your country in our list, we may be able to arange shipment to your country in most cases.</p>
                    <p>2.Shipping time is estimated and commeces from the date of shipping, not the date of order. It may take longer than expected due to custom clearance. In most cases,<br> customers are not expected to pay any additional taxes but there may be exceptions due to local customs.</p>
                    <p>3.Our long term relation with our logistic partners provides us with safe and reliable shipping to where you live.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.about-content{
    background: #fff;
}
.shippingInfo .shippingInfo-main .main-select .el-input__inner{
    border: none;
    border-bottom: 1px solid #D8DADC;
    border-radius: 0;
    font-size: 16px;
    font-family: Montserrat,SF-UI;
    font-weight: 400;
    color: #330000;
}
</style>
<style lang="scss" scoped>
@import "./css/shippingInfo.scss";
</style>

<script>
import shippingInfo from "./js/shippingInfo.js"

export default {
    name: "shippingInfo",
    components: {
        
    },
    mixins: [shippingInfo]
}
</script>